import React from "react";

// Customizable Area Start
import { Box, Breadcrumbs, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { BackArrowBlack, download, masterCard, visaLogo } from "./assets";
import moment from "moment";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Footer from "../../navigationmenu/src/Footer.web";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";

import PaymentHistoryController, {
    Props, PaymentMethodProps
} from "./PaymentHistoryController.web";
// Customizable Area End

export const PaymentMethod = ({ paymentMethod }: PaymentMethodProps) => {
    if (!paymentMethod) return <Typography className="tableDescText">Payment method not available</Typography>;

    if (paymentMethod.type === 'card') {
        const logo = paymentMethod.brand === "visa" ? visaLogo : masterCard;
        return (
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt={paymentMethod.brand} style={{ marginRight: '10px', height: '13px' }} />
                **** {paymentMethod.last4}
            </Box>
        );
    }

    if (paymentMethod.type === 'bank_account') {
        return <Typography className="tableDescText"> ACH **** {paymentMethod.last4} </Typography>;
    }

    return <Typography className="tableDescText">Payment method not available</Typography>;
};

export default class PaymentHistory extends PaymentHistoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { subscriptions, payment_method } = this.state.subscriptionDetails;
        const subscription = subscriptions ? subscriptions[0] : null;
        return (
            // Customizable Area Start
            <PaymentHistoryStyle>
                <HeaderDropdown data-test-id="headerDiv" navigation={this.props.navigation} id={""} />
                <Box className="breadCrumbDiv">
                    <Breadcrumbs className="breadCrumbs" separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButtons" color="inherit" data-test-id="breadcrumbsButtons">
                            Settings
                        </Button>
                        <Button className="breadcrumbsButtons" color="inherit" data-test-id="breadcrumbsButtons" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Typography className="breadcrumbsText">
                            Payments history
                        </Typography>
                    </Breadcrumbs>
                    <Box className="navigatorDiv">
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Payment history</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box className="lowerBox">
                        <Box className="lowerInsideBox" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography className="reviewTextTitle">Review your billing history and manage your invoices. </Typography>
                            <Button className="downloadBtn" data-test-id="downloadBtn">
                                <img style={{ color: 'white' }} src={download} />
                                <Typography className="btnTitle" onClick={this.handleDownload}>Download all</Typography>
                            </Button>
                        </Box>
                        <div ref={this.targetRef}>
                            <TableContainer className="tableContainer">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableTitle">Order number</TableCell>
                                            <TableCell className="tableTitle">Amount</TableCell>
                                            <TableCell className="tableTitle">Type of Plan</TableCell>
                                            <TableCell className="tableTitle">Payment date</TableCell>
                                            <TableCell className="tableTitle">Payment method</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="tableDescText"> {subscription ? subscription.subscription_id : 'N/A'}</TableCell>
                                            <TableCell className="tableDescText">${subscription ? subscription.amount : 'N/A'}</TableCell>
                                            <TableCell className="tableDescText">{subscription ? subscription.plan_name : 'N/A'} Plan</TableCell>
                                            <TableCell className="tableDescText">{subscription && subscription.start_date ? moment(subscription.start_date).format('MMM DD, YYYY') : 'N/A'}</TableCell>
                                            <TableCell className="tableDescText">
                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                    <PaymentMethod paymentMethod={payment_method} />
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Button className="seeDetailsBtn" data-test-id="seeDetailsButton" onClick={this.handleSeeDetails}>See Details</Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />

            </PaymentHistoryStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PaymentHistoryStyle = styled("div")({
    "& .headerBox": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        borderBottom: '1px solid #E7E5E4'
    },
    "& .profileBox": {
        display: 'flex'
    },
    "& .breadCrumbDiv": {
        marginRight: '20px',
        marginLeft: '20px',
        padding: '30px',
        '@media (max-width: 1100px)': {
            marginLeft: '30px',
            marginRight: '10px',
            padding: '20px',
        },
        '@media (max-width: 779px)': {
            marginLeft: '10px',
            padding: '10px',
            marginRight: '5px',
        },
    },
    "& .breadCrumbs": {
        marginLeft: '63px',
        '@media (max-width: 1100px)': {
            marginLeft: '40px',
        },
        '@media (max-width: 779px)': {
            marginLeft: '20px',
        },
    },
    "& .navigatorDiv": {
        alignItems: 'center',
        display: 'flex',
        marginLeft: '1%',
        marginTop: '15px',
        '@media (max-width: 1100px)': {
            alignItems: 'center',
            display: 'flex',
            marginLeft: '1%',
            marginTop: '10px',
        },
        '@media (max-width: 779px)': {
            marginTop: '5px',
            alignItems: 'center',
            display: 'flex',
        },
    },
    "& .backButton": {
        '@media (max-width: 779px)': {
            width: '5px',
            height: '5px'
        },
    },
    "& .breadcrumbsButtons": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        color: "#57534E",
        lineHeight: "22px",
        "& .MuiButton-label": {
            textTransform: "none",
        },
        '@media (max-width: 1100px)': {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 500,
        },
        '@media (max-width: 779px)': {
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: 500,
        },
    },
    "& .breadcrumbsText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
        '@media (max-width: 1100px)': {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: 500,
        },
        '@media (max-width: 779px)': {
            lineHeight: "18px",
            fontWeight: 500,
            fontSize: "16px",
        },
    },
    "& .tableContainer": {
        margin: '20px 0',
        borderRadius: '8px',
        border: "1px solid #E7E5E4",
        marginTop: '30px',
        '@media (max-width: 1100px)': {
            margin: '20px 0',
            marginTop: '30px',
        },
        '@media (max-width: 779px)': {
            margin: '20px 0',
            marginTop: '20px',
        },
    },
    "& .titleTypography": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
        '@media (max-width: 1100px)': {
            fontSize: "30px",
            fontWeight: 700,
        },
        '@media (max-width: 779px)': {
            fontSize: "20px",
            fontWeight: 700,
            marginLeft: '5px'
        },
    },
    "& .tableTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
        '@media (max-width: 1100px)': {
            fontSize: "14px",
            lineHeight: "22px",
        },
        '@media (max-width: 779px)': {
            fontSize: "13px",
            lineHeight: "18px",
        },
    },
    "& .tableDescText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#1C2110",
    },
    "& .seeDetailsBtn": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#557C29",
        "& .MuiButton-label": {
            textTransform: 'none'
        },
    },
    "& .lowerBox": {
        marginLeft: '15%',
        marginRight: '15%',
        borderRadius: '8px 8px 32px 8px',
        padding: '40px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        marginTop: '46px',
        marginBottom: '5%',
        '@media (max-width: 1100px)': {
            marginLeft: '5%',
            marginRight: '5%',
            padding: '20px',
            marginTop: '30px',
            marginBottom: '5%',
        },
        '@media (max-width: 779px)': {
            marginLeft: '5%',
            marginRight: '5%',
            padding: '10px',
            marginTop: '15px',
            marginBottom: '5%',
        },
    },
    "& .lowerInsideBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .reviewTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "26px",
        color: "#1C2110",
        '@media (max-width: 1100px)': {
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "26px",
        },
        '@media (max-width: 779px)': {
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
        },
    },
    "& .downloadBtn": {
        backgroundColor: '#8CA74B',
        textTransform: 'none',
        borderRadius: '8px',
        padding: '5px',
        height: '44px',
        width: '170px',
        '@media (max-width: 1100px)': {
            padding: '5px',
            height: '44px',
            width: '170px',
        },
        '@media (max-width: 779px)': {
            padding: '5px',
            height: '44px',
            width: '190px',
        },
    },
    "& .btnTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        marginLeft: '8px',
        '@media (max-width: 779px)': {
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: '4px',
        },
    }
});
// Customizable Area End