import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import "../../../blocks/landingpage/assets/style.css"
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";
import Header from "../../../components/src/Header.web";
import {
  arrow1,
  arrow2,
  arrow3,
  baby,
  bgImageTop,
  camera,
  couple,
  family,
  familyTree,
  girl,
  group1,
  group2,
  group3,
  imgBottom1,
  imgBottom2,
  imgBottom3,
  imgBottom4,
  imgBottom5,
  imgBottom6,
  lady,
  leaf,
  library,
  moment,
  mosaic,
  outlineCheck,
  pin1,
  pin2,
  recipes,
  sideImg,
} from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <LandingPageStyle>
        <Header
          data-test-id="header"
          onLoginClick={this.onLoginClick}
          onRegisterClick={this.onRegisterClick}
          getHeaderData={() => { }}         />
        <Box position="relative">
          <Box>
            <img src={bgImageTop} style={{ width: "100%" }} />
          </Box>
          <Box className="imageTextContainer">
            <Box>
              <Typography className="topTitle">
                Unveiling your Visual Story
              </Typography>
              <Typography className="topSubTitle">
                Explore the magic of My Kinships, where your photos turn into
                stories, your recipes become inspiration, and your family history
                unfolds with each click. Join us in capturing, sharing, and
                connecting in a whole new way.
              </Typography>
              <Button 
                className="communityBtn"
                variant="contained"
                data-test-id="communityBtn"
                color="primary"
                onClick={this.communityBtn}
              >
                Join the community
              </Button>
            </Box>
            <Box>
              <img style={{ height: '65%', width: '80px', transform: "translateX(38%)" }} src={sideImg} />
            </Box>
          </Box>
        </Box>
        <Box style={{ backgroundColor: "white" }}>
          <Box>
            <img className="imgBottom1" src={imgBottom1} />
            <img className="imgBottom2" src={imgBottom2} />
          </Box>
          <Box className="secondPortion">
            <Box >
              <Box>
                <img className="backgroundImage" src={group1} />
                <img className="ladyImg" src={group2} />
              </Box>
              <Box className="thirdPortion">
                <img className="leftImg" src={imgBottom3} />
                <img className="rightGroupImg" src={group3} />
              </Box>
            </Box>
            <Box className="rightSideContainer">
              <Box className="rightSideBox">

                <Typography className="photoTitle">Photos</Typography>
                <Typography className="photoSubTitle">
                  Witness the magic of your life's most enchanting moments. Our
                  platform seamlessly transforms your photos into visual
                  narratives, creating a stunning chronicle of your journey.
                  From milestones to everyday delights, each image is a chapter
                  waiting to be explored.
                </Typography>
              </Box>
              <Box className="arrowBox">
                <img src={arrow1} />
                <img
                  src={arrow3}
                  style={{ margin: "0 -4px", marginTop: "54px" }}
                />
                <img src={arrow2} style={{ marginTop: "53px" }} />
              </Box>
              <Box>
                <Box className="imgsBox">
                  <img className="img1" src={camera} />
                  <img className="img2" src={mosaic} />
                  <img className="img3" src={library} />
                </Box>
                <Box className="textInfoBox">
                  <Typography className="imgInfo1">
                    Capture the moment instantly with direct access to your
                    device's camera.
                  </Typography>
                  <Typography className="imgInfo2">
                    Enjoy hassle-free organization.
                  </Typography>
                  <Typography className="imgInfo3">
                    Create personalized photo albums with ease.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="thirdDiv">
          <img className="imgBottom4" src={imgBottom4} />
          <Box>
            <img style={{ padding: "40px", marginLeft: '10px' }} src={moment} />
          </Box>
          <Box className="upperDiv">
            <Box>
              <img
                src={baby}
                className="upperDivchildnearest"
              />
            </Box>
            <Box style={{ }}className="upperDivparent" >
              <Typography className="upparTitleText">Years Unfolded</Typography>
              <Typography className="subUpparTitle">
                Every year tells a unique story. From the earliest snapshots to
                recent adventures, your visual timeline awaits
              </Typography>
              <Box style={{ display: "flex", justifyContent: "center", marginLeft: '-35px' }}>
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(20%)",
                  }}
                  src={pin1}
                />
              </Box>
            </Box>

            <Box className="upparTitleTextmiddle">
              <img src={lady} />
            </Box>
            <Box
              style={{ }}
              className="upperDivparent"
            >
              <Typography className="upparTitleText">
                A Visual Odyssey
              </Typography>
              <Typography className="subUpparTitle">
                From the first frame to the latest, experience a visual odyssey
                that encapsulates the essence of your journey.
              </Typography>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(20%)",
                  }}
                  src={pin1}
                />
              </Box>
            </Box>

            <Box>
              <img src={family} />
            </Box>
          </Box>
          <div className="Line" />


          <Box className="lowerDiv">
            <Box
              className="arrangeBox"
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "80px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(-33%)",
                  }}
                  src={pin2}
                />
              </Box>
              <Typography className="upparTitleText">
                Time Capsule of Memories
              </Typography>
              <Typography className="subUpparTitle">
                Journey through the years. Relive each precious memory captured
                in a visual time capsule that spans the timeline of your life
              </Typography>
            </Box>
            <Box
            className="shiftedGirl"
            > <img src={girl} /> </Box>
            <Box
              className="arrangeBox1"
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "80px",
                }}

              >
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(-33%)",
                  }}
                  src={pin2}
                />
              </Box>
              <Typography className="upparTitleText">
                Chronicles of Joy
              </Typography>
              <Typography className="subUpparTitle">
                Each click unveils a chapter of joy, a visual narrative of the
                moments that shape your story.
              </Typography>
            </Box>
            <Box className="shiftedGirl2">
              <img src={couple} />
            </Box>
            <Box
              className="arrangeBox2"
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "80px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    transform: "translateY(-33%)",
                  }}
                  src={pin2}
                />
              </Box>
              <Typography className="upparTitleText">
                Swiftly Sorted Memories
              </Typography>
              <Typography className="subUpparTitle">
                Memories are meticulously organized for swift retrieval. Enjoy
                the ease of finding your cherished photos, intelligently
                categorized by year.
              </Typography>
            </Box>
          </Box>

        </Box>
        <img className="imgBottom5" src={imgBottom5} />
        <Box className="forthDiv">
          <img className="imgBottom6" src={imgBottom6} />
          <Box className="treeMain">
            <Typography className="discoverText">Discover your </Typography>
            <Typography className="treeText">Family Tree</Typography>
          </Box>
          <Box className="treeMainDiv">
            <Box className="treeImg">
              <img className="imgTree" src={familyTree} />
            </Box>
            <Box className="treeDecBox">
              <Box className="treeDesMain">
                <Box className="treeMainTitleLeafBox">
                  <img src={leaf} />
                  <Typography className="treeMainTitle">
                    Trace Your Roots{" "}
                  </Typography>
                </Box>
                <Typography className="treeSubTitle">
                  Uncover the branches of your family tree, tracing back
                  generations to understand your heritage and familial
                  connections.
                </Typography>
              </Box>
              <Box className="treeDesMain">
                <Box className="treeMainTitleLeafBox">
                  <img src={leaf} />
                  <Typography className="treeMainTitle">
                    Visualize Relationships
                  </Typography>
                </Box>
                <Typography className="treeSubTitle">
                  Our family tree feature provides a clear and intuitive
                  visualization of relationships, helping you comprehend the
                  intricate web of familial ties.
                </Typography>
              </Box>
              <Box className="treeDesMain">
                <Box className="treeMainTitleLeafBox">
                  <img src={leaf} />
                  <Typography className="treeMainTitle">
                    Preserve Family History
                  </Typography>
                </Box>
                <Typography className="treeSubTitle">
                  Safeguard and preserve your family's unique history by
                  documenting important events, stories, and details within the
                  context of your evolving family tree.
                </Typography>
              </Box>
              <Box className="treeDesMain">
                <Box className="treeMainTitleLeafBox">
                  <img src={leaf} />
                  <Typography className="treeMainTitle">
                    Collaborative Exploration
                  </Typography>
                </Box>
                <Typography className="treeSubTitle">
                  Invite family members to contribute, ensuring a collaborative
                  effort in building and expanding the family tree for a
                  comprehensive and shared understanding of your ancestry.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="fifthDiv">
          <Box className="recipesDiv">
            <Typography className="recipesTitle">Recipes</Typography>
            <Typography className="recipesSubTitle">
              Join our community on the landing page where users can connect,
              explore, and share their favorite recipes. Embrace the joy of
              culinary creativity as you contribute to a collective tapestry of
              delicious experiences
            </Typography>
            <Button style={{marginTop:'40px'}}
              className="communityBtn"
              data-test-id="communityButton"
              onClick={this.communityBtn}
            >
              Join the community
            </Button>
          </Box>
          <Box className="recipesBox">
            <Box className="receipeTextMain">
              <Typography className="receipeText">Share the</Typography>
              <Typography className="receipeSubText">Flavors</Typography>
              <Typography className="receipeText">of Your</Typography>
              <Typography className="receipeText">World</Typography>
            </Box>
            <Box className="receipeImg">
              <img  src={recipes} className="receipeTextMainchild" />
            </Box>
          </Box>
        </Box>
        <Box className="sixthDiv">
          <Box className="plansDiv">
            <Typography className="plansTitle">Plans and pricing</Typography>
            <Typography className="recipesSubTitle">
              Explore pricing plans tailored to your preferences. From basic to
              premium, find the perfect fit for your journey of capturing,
              sharing, and connecting
            </Typography>
          </Box>
          <Box className="priceMainBox">
            {this.state.subscriptionData.map((plan, index) => {
              return <Box key={plan.id} className="priceBox">
                <Typography className="priceTitle">{plan.name}</Typography>
                <Typography
                  className="priceSubTitle"
                  dangerouslySetInnerHTML={{ __html: plan.details }}
                />
                <Box className={index === 1 ? "priceCenterDivGreen" : "priceCenterDiv"}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography className="dollar"> $ </Typography>
                    <Typography className="priceTag"> {plan.price}</Typography>
                  </Box>
                  <Typography className="monthText">/ Month</Typography>
                </Box>
                <Typography className="storageText">
                  <img src={outlineCheck} alt="check icon" /> {plan.storage}{" "}
                  {plan.storage_unit} of storage
                </Typography>
                <Button
                  className="startBtn"
                  data-test-id={`priceBtn${plan.id}`}
                  onClick={this.startNowBtn}
                >
                  Start now
                </Button>
              </Box>
            })}
          </Box>
        </Box>
        <Footer navigation={this.props.navigation} id={""} />
      </LandingPageStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const LandingPageStyle = styled("div")({
  "& .imageTextContainer": {
    width: "45%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "2rem",
    display: "flex",
    "@media (max-width: 1120px)": {
      width: "46%",
    },
    "@media (max-width: 945px)": {
      width: "47%",
    },
  },
  "& .upparTitleTextmiddle": {
   marginRight: "70px",
    "@media (max-width: 820px)": {
     marginRight: 0
    },
  },
  "& .upperDivchildnearest": {
    marginTop: "56px", marginRight: "100px",
     "@media (max-width: 820px)": {
      marginTop: "56px", marginRight: 0
     },
   },
  "& .topTitle": {
    fontWeight: "400",
    fontFamily: "Abygaer",
    fontSize: "72px",
    lineHeight: "85px",
    color: "#FFFFFF",
    marginBottom: "36px",
    "@media (max-width: 985px)": {
      fontSize: "60px",
      lineHeight: "60px",
      marginBottom: "25px",
    },
    "@media (max-width: 794px)": {
      fontSize: "45px",
      lineHeight: "45px",
      marginBottom: "15px",
    },
    "@media (max-width: 634px)": {
      fontSize: "35px",
      lineHeight: "35px",
      marginBottom: "5px",
    },
    "@media (max-width: 465px)": {
      fontSize: "23px",
      lineHeight: "35px",
      marginTop:"3rem"
    },
  },
  "& .topSubTitle": {
    color: "#FFFFFF",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    fontStyle: "Poppins",
    "@media (max-width: 985px)": {
      fontSize: "18px",
      lineHeight: "22px",
    },
    "@media (max-width: 769px)": {
      fontSize: "16px",
      lineHeight: "20px",
    },
    "@media (max-width: 569px)": {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  "& .communityBtn": {
    marginTop:"60px",
    borderRadius: "8px",
    padding: "16px",
    backgroundColor: "#8CA74B",
    width: "292px",
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "16px",
      fontStyle: "Poppins",
      lineHeight: "24px",
      color: "#FFFFFF",
    },
    "@media (max-width: 985px)": {
      marginTop: "70px",
      width: "267px",
      padding: "14px",
    },
    "@media (max-width: 769px)": {
      width: "237px",
      padding: "10px",
    },
    "@media (max-width: 486px)": {
      marginTop:'0px',
      width: "200px",
      padding: "7px",
    },
  },
  "& .imgBottom1": {
    position: "absolute",
    transform: "translateY(-60%)",
    marginLeft: "21%",
    "@media only screen and (min-device-width: 350px) and (max-device-width: 680px)":
    {
      width: "135px",
      transform: "translateY(-45%)",
      marginLeft: "21%",
    },
    "@media only screen and (min-device-width: 680px) and (max-device-width: 1150px)":
    {
      width: "234px",
      transform: "translateY(-59%)",
      marginLeft: "21%",
    },
  },
  "& .imgBottom2": {
    position: "absolute",
    transform: "translateY(-45%)",
    right: "0rem",
    display: "flex",
    flexDirection: "column",
    "@media only screen and (min-device-width: 350px) and (max-device-width: 680px)":
    {
      width: "135px",
      transform: "translateY(-48%)",
      right: "0rem",
    },
    "@media only screen and (min-device-width: 680px) and (max-device-width: 1150px)":
    {
      width: "192px",
      transform: "translateY(-53%)",
      right: "0rem",
    },
  },
  "& .backgroundImage": {
    marginTop: "7%",
    "@media only screen and (min-device-width: 532px) and (max-device-width: 771px)":
    {
      width: "265px",
    },
    "@media only screen and (min-device-width: 771px) and (max-device-width: 824px)":
    {
      width: "385px",
    },
  },
  "& .ladyImg": {
    "@media only screen and (min-device-width: 532px) and (max-device-width: 771px)":
    {
      width: "265px",
    },
    "@media only screen and (min-device-width: 771px) and (max-device-width: 824px)":
    {
      width: "385px",
    },
  },
  "& .overlayImage": {
    position: "absolute",
    top: "167%",
    left: "85px",
  },
  "& .overlayImg2": {
    position: "absolute",
    top: "172%",
    left: "32.5%",
  },
  "& .leftImg": {
    transform: "translateY(-10%)",
    "@media only screen and (min-device-width: 532px) and (max-device-width: 771px)":
    {
      width: "186px",
      transform: "translateY(-36%)",
    },
    "@media only screen and (min-device-width: 771px) and (max-device-width: 824px)":
    {
      width: "215px",
      transform: "translateY(-16%)",
    },
    "@media only screen and (min-device-width: 369px) and (max-device-width: 532px)":
    {
      width: "123px",
      transform: "translateY(12%)",
    },
  },
  "& .secondPortion": {
    display: "flex",
    gap: "10%",
    "@media (max-width: 600px)": { 
      flexDirection: "column", 
    },
    "@media (max-width: 486px)": { 
      alignItems: "center",
    },
    "@media (max-width: 820px)": { 
      flexDirection: "column", 
      alignItems: "center", 
    },
   

  },
  "@media (max-width: 600px)": { 
    "& .thirdPortion": {
      display: "flex",
    justifyContent:"center",
    alignItems:"center"
  },


},
 

"& .receipeTextMainchild":{
  width: "95%", transform: "translateX(6%)",
  "@media (max-width: 486px)": {
    width:"100%",
     transform: "translateX(-5%)"
  },
  
  "@media (max-width: 820px)": {
    width:"100%",
     transform: "translateX(-4%)"
  }


},

 // Adjust the screen width as needed

"& .arrangeBox": {
  width: "276px", marginTop: "25px", marginLeft: "142px" ,
  "@media (max-width: 486px)": { 
    marginLeft: 0,
  },
  "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
    marginLeft: 0,
    width:"100%"
  },
},
"& .arrangeBox1": {
  width: "241px", marginTop: "25px", marginLeft: "48px",
  "@media (max-width: 486px)": { 
    marginLeft: "-31px",
  },
  "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
    marginLeft: 0,
    width:"100%"
  },
}, 

"& .shiftedGirl": {
  marginTop: "40px", width: "210px" ,
  "@media (max-width: 486px)": { 
    marginTop: 0, width: "273px" 
  },
},
"& .shiftedGirl2": {
  marginTop: "40px", marginLeft: "10px" ,
  "@media (max-width: 486px)": { 
    marginTop: 0, marginLeft: 0 ,
  },
},
"& .arrangeBox2": {
  width: "260px", marginTop: "25px", marginLeft: "34px",
  "@media (max-width: 486px)": { 
    marginLeft: 0,
  },
  "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
    marginLeft: 0,
    width:"100%"
  },
},
  "& .rightGroupImg": {
    position: "absolute",
    marginLeft: "-66px",
    "@media (max-width: 486px)": { 
      marginLeft: 0,
    },
  },
  "& .rightSideContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      marginTop:"3rem"
    },
  },
  "& .rightSideBox": {
    height: "245px",
    width: "450px",
    borderRadius: "16px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    padding: "45px",
    marginTop: "186px",
    "@media only screen and (min-device-width: 340px) and (max-device-width: 554px)":
    {
      padding: "20px",
      marginTop: "100px",
      width: "300px",
      height: "290px",
    },
    "@media only screen and (min-device-width: 554px) and (max-device-width: 980px)":
    {
      padding: "20px",
      marginTop: "40px",
      width: "345px",
      height: "290px",
    },
  },
  "& .arrowBox": {
    marginLeft: "25px",
    display: "flex",
    alignItems: "center",
    "@media only screen and (min-device-width: 540px) and (max-device-width: 1750px)":
    {
      display: "flex",
      justifyContent: "center",
    },
  },
  "& .imgsBox": {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 600px)": { 
      flexDirection: "column", 
    },
    "@media (max-width: 486px)": { 
      alignItems: "center",
    },
  },
  "& .photoTitle": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#305328",
    letterSpacing: "-1.5%",
    marginBottom: "22px",
  },
  "& .photoSubTitle": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C2110",
  },
  "& .imgInfo1": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C2110",
    alignItems: "center",
    marginTop: "20px",
    marginLeft: "-22px",
    width: "320px",
  },
  "& .imgInfo2": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C2110",
    alignItems: "center",
    marginTop: "20px",
    marginLeft: "17px",
    width: "280px",
  },
  "& .imgInfo3": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C2110",
    alignItems: "center",
    marginTop: "20px",
    marginLeft: "3px",
    width: "270px",
  },
  "& .img1": {
    marginTop: "20px",
    "@media (max-width: 600px)": { 
      marginTop: 0,
    },
    "@media (max-width: 486px)": { 
      alignItems: "center", 
    },
  },
  "& .img2": {
    marginTop: "20px",
    marginLeft: "172px",
    "@media (max-width: 600px)": {
      marginLeft: 0,
    },
    "@media (max-width: 486px)": { 
      alignItems: "center", 
    },
  },
  "& .img3": {
    marginTop: "20px",
    marginLeft: "170px",
    "@media (max-width: 600px)": { 
      marginLeft: 0,
    },
    "@media (max-width: 486px)": { 
      alignItems: "center", 
    },
  },
  "& .textInfoBox": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "656px",
    "@media (max-width: 600px)": { 
      flexDirection: "column",
    },
    "@media (max-width: 486px)": { 
      alignItems: "center",
    },
  },
  "& .imgBottom4": {
    position: "absolute",
    transform: "translateY(-50%)",
    right: "0rem",
    display: "flex",
    flexDirection: "column",
  },
  "& .thirdDiv": {
    backgroundColor: "#e7edd8",
    clipPath: "ellipse(100% 100% at 50% 0%)",
    marginTop: '100px',
    "@media (max-width: 486px)": { 
      clipPath: "none", 
    },
    "@media (max-width: 820px)": { 
      clipPath: "none", 
    },
  },
  "& .imgesDivMain": {
    display: "flex",
  },
  "& .upperDiv": {
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 600px)": { 
      flexDirection: "column", 
    },
    "@media (max-width: 486px)": { 
      alignItems: "center", 
    },
    "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      flexDirection: "column",
      alignItems: "center", 
    },
  },
  "& .upperDivparent": {
    width: "254px", marginTop: "25px",
    "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      width: "100%", marginTop: 0 ,
      paddingLeft:"4rem"
    },
  },
  "& .lowerDiv": {
    display: "flex",
    justifyContent: "space-around",
    width: "90%",
    marginLeft: "30px",
    marginRight: "30px",
    "@media (max-width: 600px)": {
      flexDirection: "column", 
    },
    "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      flexDirection: "column", 
      alignItems: "center",
    },
    "@media (max-width: 486px)": { 
      alignItems: "center",
    },
  },
  "& .Line": {
    borderBottom: "5px solid #557C29",
    borderRadius: "16px",
    display: "flex",
    transform: "translateY(0px)",
  },
  "& .imgBottom5": {
    position: "absolute",
    transform: "translateY(-70%)",
    marginLeft: "21%",
  },
  "& .upparTitleText": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#000000",
    marginBottom: "8px",
   "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      fontSize: "35px",
    },
  },
  "& .subUpparTitle": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "40px",
   "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      fontSize: "25px",
    },
  },
  "& .imgBottom6 ": {
    position: "absolute",
    transform: "translateY(15%)",
    right: "0rem",
    display: "flex",
    flexDirection: "column",
  },
  "& .treeMain": {
    display: "flex",
    marginLeft: "135px",
    marginTop: "70px",
   "@media (max-width: 600px)": { 
      flexDirection: "column", 
      alignItems:"center",
      marginLeft:0
    },

  },
  "& .treeMainDiv": {
    display: "flex",
    "@media (max-width: 600px)": { 
      flexDirection: "column", 
    },
   "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { // Adjust the screen width as needed
      flexDirection: "column", 
      alignItems: "center",
      paddingRight:"2rem" 
    },
   
    "@media (max-width: 486px)": { 
      alignItems: "center",
      paddingRight:"2rem" 
    },
  },
  "& .discoverText": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#305328",
    letterSpacing: "-1.5%",
    "@media only screen and (min-device-width: 369px) and (max-device-width: 900px)":
    {
      fontSize: "40px",
      lineHeight: "46px",
    },
  },
  "& .treeText": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
    letterSpacing: "-1.5%",
    backgroundColor: "#8CA74B",
    height: "60px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginLeft: "10px",
    "@media only screen and (min-device-width: 369px) and (max-device-width: 900px)":
    {
      fontSize: "40px",
      lineHeight: "46px",
    },
  },
  "& .treeImg": {
    padding: "50px 0px 0px 50px",
    marginTop: "45px",
    width: "37.5%",
    marginLeft: "60px",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 850px)":
    {
      marginTop: "45px",
      padding: "1px",
      width: "100%",
      marginLeft: "30px",
    },
  },
  "& .imgTree": {
    width: "100%",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 850px)":
    {
      width: "100%",
    },
  },
  "& .treeDecBox": {
    width: "412px",
    margin: "100px 0 0 104px",
    "@media (max-width: 486px)": { 
     width:"100%",
     paddingLeft:"1rem"
    },
    "@media only screen and (min-device-width: 450px) and (max-device-width: 850px)":
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width:"90%",
      marginLeft:"1rem"
      
   
    },
  },
  "& .treeMainTitle": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#1C2110",
    marginLeft: "20px",
   "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      fontSize: "32px",
      width:"100%",
      paddingLeft:"1rem",
     },
  },
  "& .treeMainTitleLeafBox": {
    display: "flex", 
    alignItems: "center",
  },
  "& .treeSubTitle": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop: "2px",
    marginLeft: "40px",
    marginBottom: "30px",
   "@media only screen and (min-device-width: 486px) and (max-device-width: 820px)": { 
      fontSize: "30px",
      width:"100%",
      paddingLeft:"1rem"
     },
  },
  "& .treeDesMain": {
    marginTop: "32pxs",
  },
  "& .recipesDiv": {
    padding: "60px",
    marginLeft: "105px",
    marginRight: "50px",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 850px)":
    {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
  "& .recipesTitle": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#305328",
    letterSpacing: "-1.5%",
    marginBottom: "20px",
  },
  "& .recipesSubTitle": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1C2110",
  },
  "& .comminutyBtn": {
    backgroundColor: "#8CA74B",
    height: "56px",
    borderRadius: "8px",
    padding: "16px",
    Gap: "8px",
    fontWeight: 700,
    fontSize: "16px",
    "& .MuiButton-label": {
      lineHeight: "24px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "Poppins",
      color: "#FFFFFF",
    },
  },
  "& .recipesBox": {
    display: "flex",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 850px)":
    {
      display: "block",
    },
  },
  "& .receipeTextMain": {
    marginLeft: "160px",
    marginTop: "160px",
    "@media (max-width: 486px)": { 
      marginLeft: "68px",
      marginTop: 0,
   
     },
 
  },
  "& .receipeText": {
    lineHeight: "100px",
    fontWeight: 700,
    fontSize: "100px",
    fontFamily: "Poppins",
    color: "#305328",
    letterSpacing: "-1.5%",
    "@media (max-width: 486px)": { 
      fontSize: "40px",
     },
  },
  "& .receipeSubText": {
    lineHeight: "100px",
    fontWeight: 700,
    fontSize: "100px",
    fontFamily: "Inter",
    color: "#8CA74B",
    letterSpacing: "-1.5%",
    "@media (max-width: 486px)": { 
      fontSize: "60px",
     },
  },
  "& .receipeImg": {
    right: "0rem",
    position: "absolute",
    "@media (max-width: 486px)": { 
      position: "unset",
     },
     "@media (max-width: 820px)": { 
      position: "unset",
     },
  },
  "& .sixthDiv": {
    marginLeft: "105px",
    marginRight: "50px",
    padding: "60px",
    marginTop: "90px",
    "@media (max-width: 1150px)": {
      marginLeft: "0px",
      marginRight: "54px",
      padding: "30px",
      marginTop: "90px",
    },
  },
  "& .plansDiv": {
    marginBottom: "56px",
  },
  "& .plansTitle": {
    lineHeight: "56px",
    fontWeight: 700,
    fontSize: "48px",
    fontFamily: "Poppins",
    color: "#305328",
    letterSpacing: "-1.5%",
    marginBottom: "24px",
  },
 "& .priceMainBox": {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  width: "94%",
  gap: "25px",
  "@media (max-width: 768px)": {
    flexDirection: "column", 
    alignItems: "center",
    gap: "15px",
  },
  "@media (max-width: 486px)": {
    width: "100%",
    marginLeft:"28px"
  },
},
"& .priceBox": {
  width: "392px",
  borderRadius: "16px",
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  "@media (max-width: 768px)": {
    width: "90%",
  },
  "@media (max-width: 486px)": {
    width: "100%", 
  },
},
"& .priceTitle": {
  lineHeight: "44px",
  fontWeight: 700,
  fontSize: "36px",
  fontFamily: "Poppins",
  color: "#1C2110",
  letterSpacing: "-1%",
  marginBottom: "5px",
  marginTop: "40px",
  textAlign: "center",
  "@media (max-width: 486px)": {
    fontSize: "28px",
  },
},
"& .priceSubTitle": {
  lineHeight: "26px",
  fontWeight: 400,
  fontSize: "18px",
  fontFamily: "Poppins",
  color: "#1C2110",
  marginBottom: "20px",
  textAlign: "center",
  "@media (max-width: 486px)": {
    fontSize: "16px",
  },
},
"& .priceCenterDiv": {
  backgroundColor: "#8CA74B",
  height: "140px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 486px)": {
    height: "120px",
  },
},
"& .priceCenterDivGreen": {
  backgroundColor: "#557C29",
  height: "140px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 486px)": {
    height: "120px",
  },
},
"& .priceTag": {
  lineHeight: "56px",
  fontWeight: 500,
  fontSize: "64px",
  fontFamily: "Inter",
  color: "#FFFFFF",
  textAlign: "center",
  letterSpacing: "-1.5%",
  padding: "13px",
  "@media (max-width: 486px)": {
    fontSize: "48px", 
  },
},
"& .dollar": {
  lineHeight: "56px",
  fontWeight: 300,
  fontSize: "32px",
  fontFamily: "Inter",
  color: "#FFFFFF",
  letterSpacing: "-1.5%",
  "@media (max-width: 486px)": {
    fontSize: "24px",
  },
},
"& .monthText": {
  lineHeight: "28px",
  fontWeight: 400,
  fontSize: "20px",
  fontFamily: "Poppins",
  color: "#FFFFFF",
  marginBottom: "20px",
  textAlign: "center",
  display: "block",
  alignItems: "center",
  "@media (max-width: 486px)": {
    fontSize: "16px",
  },
},
"& .storageText": {
  lineHeight: "28px",
  fontWeight: 400,
  fontSize: "20px",
  fontFamily: "Poppins",
  color: "#1C2110",
  marginBottom: "40px",
  textAlign: "center",
  marginTop: "20px",
  "@media (max-width: 486px)": {
    fontSize: "16px",
  },
},
"& .startBtn": {
  backgroundColor: "#8CA74B",
  borderRadius: "8px",
  padding: "10px",
  marginTop: "20px",
  marginBottom: "48px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
  width: "182px",
  "@media (max-width: 486px)": {
    width: "150px", 
  },
  "& .Muibutton-label": {
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Poppins",
    color: "#FFFFFF",
    textAlign: "center",
    "@media (max-width: 486px)": {
      fontSize: "14px", 
    },
  },
},
});
// Customizable Area End