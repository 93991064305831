import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Webcam from "react-webcam";
import React, { ChangeEvent, RefObject, createRef } from "react";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showModal: boolean;
  EditPhotoClick: boolean;
  showDeleteConfirmModal: boolean;
  showMediaModal: boolean;
  isCaptureEnable: boolean;
  userImg: string;
  selectedImg: string;
  uploadMedia: boolean;
  fullName: string,
  email: string,
  birthDate: Date | null;
  contactNumber: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  newsToggleChecked: boolean,
  mediaImg: File | undefined,
  apiError: string,
  emptyDataError: string,
  removePhotoError: string,
  dragging: boolean,
  deviceSupportError: string,
  formSucessError: string,
  formSuccess: string,
  showSuccessSnackbar: boolean,
  snackbarMessage: string,
  errorMsg: any,
  availableCities?: any;
  getProfile: any;
  zipcodeError: string;
  snackbarType: 'success' | 'error';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableEditProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDeleteAccountId: string = '';
  getUserProfilePhotoId: string = '';
  userRemoveProfilePhotoId: string = '';
  EditUserProfileId: string = '';
  saveProfilePictureId: string = '';
  getUserAccountDetailsId: string = '';
  zipcodeApiId: string = '';
  webcamRef: RefObject<Webcam>;
  fileInputRef = createRef<HTMLInputElement>();
  fullscreenRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    // Customizable Area Start
    this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.webcamRef = createRef<Webcam>()
    this.fullscreenRef = React.createRef<HTMLDivElement>();
    // Customizable Area Start

    this.state = {
      // Customizable Area Start
      showModal: false,
      showDeleteConfirmModal: false,
      showMediaModal: false,
      EditPhotoClick: false,
      isCaptureEnable: false,
      userImg: '',
      selectedImg: '',
      uploadMedia: false,
      fullName: '',
      email: '',
      birthDate: null,
      contactNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      mediaImg: undefined,
      apiError: '',
      emptyDataError: '',
      removePhotoError: '',
      dragging: false,
      deviceSupportError: '',
      formSucessError: '',
      formSuccess: '',
      showSuccessSnackbar: false,
      snackbarMessage: '',
      snackbarType: 'error',
      errorMsg: '',
      newsToggleChecked: false,
      availableCities: [],
      getProfile: '',
      zipcodeError: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      if (apiRequestCallId === this.getUserAccountDetailsId) {
        const attributes = responseJson.data.attributes;
        const cities = configJSON.stateCityMap[attributes.state];
        this.setState({
          fullName: attributes.full_name,
          email: attributes.email,
          birthDate: attributes.date_of_birth ? new Date(attributes.date_of_birth) : null,
          contactNumber: attributes.full_phone_number,
          address: attributes.address,
          city: attributes.city,
          availableCities: cities,
          state: attributes.state,
          zipCode: attributes.zip_code,
          newsToggleChecked: attributes.subscribe_to_newsletter
        });
      }

      else if (apiRequestCallId === this.saveProfilePictureId) {
        this.setState({ uploadMedia: false, EditPhotoClick: false, selectedImg: this.state.userImg });
        this.getUserProfilePhoto();
      }

      else if (apiRequestCallId === this.EditUserProfileId) {
        if (responseJson?.errors && responseJson?.errors?.length !== 0) {
          const formError = " Your profile information could not be saved. "
          const errorMessage = "This email address is already associated with an existing account. Please use a different one.";
          this.setState({
            apiError: errorMessage,
            emptyDataError: '',
            showSuccessSnackbar: true,
            snackbarMessage: formError,
            snackbarType: 'error',
            zipcodeError:''
          })
        }
        else {
          const formSuccess = "Your profile information has been successfully saved."
          this.setState({
            uploadMedia: false,
            EditPhotoClick: false,
            apiError: '',
            formSuccess: formSuccess,
            showSuccessSnackbar: true,
            snackbarMessage: formSuccess,
            zipcodeError:'',
            snackbarType: 'success'
          });
          const storedEmail = localStorage.getItem("email");
          const enteredEmail = this.state.email;
          if (storedEmail !== enteredEmail) {
            localStorage.setItem("email", enteredEmail);
            this.props.navigation.navigate('TwoFactorAuth');
          }
        }
      }
      else if (apiRequestCallId === this.userRemoveProfilePhotoId) {
        this.setState({
          EditPhotoClick: false,
          selectedImg: '',
          getProfile: ''
        })
      } else if (apiRequestCallId === this.userDeleteAccountId) {
        this.setState({
          fullName: '',
          email: '',
          birthDate: null,
          contactNumber: '',
          address: '',
          city: '',
          state: '',
          zipCode: '',
          newsToggleChecked: false
        });
      } else if (apiRequestCallId === this.getUserProfilePhotoId) {
        const profileSrc = responseJson.url?.url
        this.setState({
          getProfile: profileSrc,
        })
      } else if (apiRequestCallId === this.zipcodeApiId) {
        if (responseJson?.errors) {
          const zipError = "Location not found for the provided zip code"
          this.setState({
            zipcodeError: zipError
          });
          console.log("thiss", this.state.zipcodeError)
        } else {
          this.setState({
            city: responseJson.city,
            state: responseJson.state,
            zipcodeError: ''
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start 
  async componentDidMount() {
    this.getUserAccountDetails();
    this.getUserProfilePhoto();
  }

  userProfileSchema = Yup.object({
    fullName: Yup.string()
      .matches(/^[A-Za-z]+(?:\.| [A-Za-z]+)*(?: [A-Za-z]+)*(?: \.[A-Za-z]+)*\.?$/, 'Full name must contain only alphabets')
      .required("Please fill out the required field before proceeding."),
    email: Yup.string()
      .email("The email address you provided is not valid. Please enter a valid email address (example@example.com).")
      .required("Please fill out the required field before proceeding."),
    birthDate: Yup.date()
      .nullable()
      .required("Please fill out the required field before proceeding."),
    contactNumber: Yup.string()
      .required("Please fill out the required field before proceeding."),
    address: Yup.string()
      .matches(/^(?!\s*$).+/, 'Address must contain alphabets')
      .max(100, "Address cannot be longer than 100 characters")
      .required("Please fill out the required field before proceeding."),
    city: Yup.string()
      .required("Please fill out the required field before proceeding."),
    state: Yup.string()
      .required("Please fill out the required field before proceeding."),
    zipCode: Yup.string()
      .matches(/^\d{5}$/, 'Zip code must be 5 digits')
      .required("Please fill out the required field before proceeding.")
  });

  handleKeyDown = (e: any, setFieldValue: any) => {
    const input = (e.target as HTMLInputElement).value;
    const key = e.key;
    const currentYear = new Date().getFullYear();

    if (key === 'Backspace') {
      this.handleBackspace(setFieldValue);
      return;
    }

    if (isNaN(Number(key))) {
      e.preventDefault();
      return;
    }

    if (input.length <= 2) {
      this.handleMonthInput(e, input);
    } else if (input.length <= 5) {
      this.handleDayInput(e, input);
    } else if (input.length <= 10) {
      this.handleYearInput(e, input, currentYear);
    } else {
      this.handleOverflow(e);
    }
  };

  handleBackspace = (setFieldValue: any) => {
    setFieldValue('birthDate', null);
  };

  handleMonthInput = (e: React.KeyboardEvent<HTMLDivElement>, input: any) => {
    if (input.length === 2 && parseInt(input) > 12) {
      (e.target as HTMLInputElement).value = '';
    } else if (input.length === 2) {
      (e.target as HTMLInputElement).value = `${input}/`;
    }
  };

  handleDayInput = (e: React.KeyboardEvent<HTMLDivElement>, input: string) => {
    if (input.length === 5 && parseInt(input.substring(3, 5)) > 31) {
      (e.target as HTMLInputElement).value = input.substring(0, 3);
    } else if (input.length === 5) {
      (e.target as HTMLInputElement).value = `${input}/`;
    }
  };

  handleYearInput = (e: React.KeyboardEvent<HTMLDivElement>, input: string, currentYear: number) => {
    if (input.length === 10 && parseInt(input.substring(6, 10)) > currentYear) {
      (e.target as HTMLInputElement).value = input.substring(0, 6);
    }
  };

  handleOverflow = (e: React.KeyboardEvent<HTMLDivElement>) => {
    (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.substring(0, 10);
  };

  handleCancleBtn = () => {
    this.setState({
      fullName: '',
      email: '',
      birthDate: null,
      contactNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      newsToggleChecked: false,
      emptyDataError: '',
      apiError: ''
    });
  }

  getInitials = () => {
    const name = this.state.fullName.split('@')[0];
    return name.charAt(0).toUpperCase();
  };

  handleStateChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedState = e.target.value as string;
    const cities = configJSON.stateCityMap[selectedState];
    this.setState({
      state: selectedState,
      city: '',
      availableCities: cities || [],
    });
  };

  handleCityChange = (e: any) => {
    this.setState({ city: e.target.value });
  };

  handleBirthday = (e:any) => {
    this.setState({ birthDate : e.target.value})
  }

  handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    this.setState({ newsToggleChecked: checked });
  };

  closeSuccessSnackbar = () => {
    this.setState({ showSuccessSnackbar: false });
  }
  openDeleteModel = () => {
    this.setState({ showModal: true });
  };

  closeDeleteModel = () => {
    this.setState({ showModal: false });
  };

  closeDeleteConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false });
    setTimeout(() => {
      this.props.navigation.navigate('LandingPage');
    }, 3000);
  }

  handleDeleteBtn = () => {
    const { fullName, email, birthDate, contactNumber, address, city, state, zipCode } = this.state;
    if (fullName && email && birthDate && contactNumber && address && city && state && zipCode) {
      this.setState({ showModal: true });
    }
  }

  handleConfirmDeleteBtn = () => {
    this.deleteUserAccount();
    this.setState({ showDeleteConfirmModal: true, showModal: false });
  }

  handleRemovePhotoBtn = () => {
    if (this.state.userImg || this.state.getProfile) {
      this.handleRemovePhoto()
    } else {
      const errorMessage = "This user does not have a profile photo.";
      this.setState({
        removePhotoError: errorMessage,
        EditPhotoClick: false
      })
    }
  }

  handleEditPhoto = () => {
    this.setState({ EditPhotoClick: true });
  }

  handleCloseEditPhoto = () => {
    this.setState({ EditPhotoClick: false });
  }

  handleCaptureEnable = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        this.setState({ isCaptureEnable: true });
      })
      .catch(error => {
        this.setState({
          EditPhotoClick: false,
          deviceSupportError: 'Your hardware does not support camera access. Please upload your content from your device.',
        });
      });
  }

  handleCaptureDisable = () => {
    this.setState({ isCaptureEnable: false, EditPhotoClick: false });
  }

  openUploadModal = () => {
    this.setState({ uploadMedia: true, userImg: '' })
  }

  closeUploadModal = () => {
    this.setState({ uploadMedia: false })
  }

  deleteUserAccount = () => {
    let token = localStorage.getItem("token");

    const header = {
      token: token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDeleteAccountId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_deactivation`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  submitProfileDetails = (values: any) => {
    let token = localStorage.getItem("token");

    const formdata = new FormData();
    formdata.append('full_name', values.fullName);
    formdata.append('email', values.email);
    formdata.append('date_of_birth', values.birthDate);
    formdata.append('full_phone_number', values.contactNumber);
    formdata.append('address', values.address);
    formdata.append('city', values.city);
    formdata.append('state', values.state);
    formdata.append('zip_code', values.zipCode);
    formdata.append("subscribe_to_newsletter", values.newsToggleChecked)

    const header = {
      token: token
    };

    this.setState({
      fullName: values.fullName,
      email: values.email,
      birthDate: values.birthDate,
      contactNumber: values.contactNumber,
      address: values.address,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      newsToggleChecked: values.newsToggleChecked
    });

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EditUserProfileId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/update_profile`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }
  handleMediaDragLeave = () => {
    this.setState({ dragging: false });
  };

  handleMediaDragEnter = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.setState({ dragging: true });
  };

  handleMediaDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    this.setState({ dragging: true });
  };

  handleMediaDrop = (e: {
    preventDefault: () => void;
    dataTransfer: { files: FileList };
  }) => {
    e.preventDefault();
    this.setState({ dragging: false });

    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageSrc = reader.result as string;
        this.setState({ userImg: imageSrc, mediaImg: file });
      };
      reader.readAsDataURL(file);
    }
  };


  handleRemovePhoto = () => {
    let token = localStorage.getItem("token");

    const header = {
      token: token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userRemoveProfilePhotoId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/remove_profile_picture`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  saveProfileClick = (userImg: any) => {
    let token = localStorage.getItem("token");

    const formdata = new FormData();
    formdata.append('media_profile', this.state.mediaImg as File);

    this.setState({
      selectedImg: userImg,
    })

    const header = {
      token: token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveProfilePictureId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/upload_profile_picture`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  captureProfile = () => {
    this.setState({ isCaptureEnable: false, EditPhotoClick: false });
    this.saveProfileClick(this.state.userImg);
  }

  capture = () => {
    const imageSrc = this.webcamRef.current?.getScreenshot();
    if (imageSrc) {
      this.setState({ userImg: imageSrc, EditPhotoClick: false });
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/heif'];
      const maxSizeMB = 5;

      if (validTypes.includes(file.type) && file.size <= maxSizeMB * 1024 * 1024) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageSrc = reader.result as string;
          this.setState({
            userImg: imageSrc,
            mediaImg: file,
            showMediaModal: false,
          });
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({
          EditPhotoClick: false,
          showMediaModal: true,
          errorMsg: `Your photos couldn't be uploaded. Photos should be less than ${maxSizeMB} MB and saved as JPG, PNG, GIF, TIFF, or HEIF files.`,
        });
      }
    }
  }

  tryAgainClick = () => {
    this.setState({ showMediaModal: false, uploadMedia: true })
  }

  handleMediaCloseModal = () => {
    this.setState({ showMediaModal: false })
  }

  handleUploadButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  getUserAccountDetails = () => {
    let token = localStorage.getItem("token");

    const header = {
      token: token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserAccountDetailsId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getUserProfilePhoto = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfilePhotoId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/get_profile_image`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  handleZipcodeChange = (e: any) => {
    const zipcode = e.target.value;
    this.setState({ zipCode: zipcode , zipcodeError :''});
    if (zipcode.length === 5) {
      this.getZipcodeDetails(zipcode);
    }
  };

  getZipcodeDetails = (zipcode: string) => {
    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append('zip_code', zipcode);
    const header = { token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.zipcodeApiId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/identify_location`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}